// import $ from 'jquery';
// import Swiper from 'swiper/bundle';
// import 'swiper/css';

import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'


const breakPoint = 768;
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: '+breakPoint+'px)').matches) {
    return true;
  } else {
    return false;
  }
};


/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ノードリストを配列にする
  let returnText = ''; // 最終的に返すテキスト

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //テキストの場合
      const text = node.textContent.replace(/\r?\n/g, ''); //テキストから改行コード削除
      const splitText = text.split(''); // 一文字ずつ分割
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanタグで挟んで連結
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

const spanWrapTextElements = document.querySelectorAll('.js-spanwrap')
if (spanWrapTextElements) {
  // console.log(spanWrapTextElements)
  spanWrapTextElements.forEach((spanWrapTextElement) => {
    spanWrapTextElement.innerHTML = spanWrapText(spanWrapTextElement)
  });
}

const enteractives = gsap.utils.toArray('.js-enter-active');
if (enteractives) {
  enteractives.forEach((element, index) => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top center',
        onEnter: () => element.classList.add('is-active'),
      }
    });
  });
}

const initServiceParallax = function () {
  
  const serviceSections = gsap.utils.toArray('.js-service-section');
  if (!serviceSections) {
    return;
  }
  const serviceSection1 = document.querySelector(".js-service-section--1")
  const serviceSectionImg1 = document.querySelector(".js-service-section-img--1")
  const serviceSection2 = document.querySelector(".js-service-section--2")
  const serviceSectionImg2 = document.querySelector(".js-service-section-img--2")
  // gsap.set(serviceSectionImg1, { opacity: 0, scale: 0 })
  // gsap.set(serviceSectionImg2, { opacity: 0, scale: 0 })
  gsap.fromTo(serviceSectionImg1, {
    opacity: 0,
    // scale: 0,
    y: 30,
  }, {
    opacity: 1,
    // scale: 1,
    y: 0,
    scrollTrigger: {
      trigger: serviceSection1,
      start: 'top bottom',
      end: 'bottom center',
      toggleActions: "play reverse play reverse",
    },
  });
  gsap.fromTo(serviceSectionImg2, {
    opacity: 0,
    // scale: 0,
    y: 30,
  }, {
    opacity: 1,
    // scale: 1,
    y: 0,
    scrollTrigger: {
      trigger: serviceSection2,
      start: 'top center',
      toggleActions: "play reverse play reverse",
    },
  });

  // gsap.to(serviceSectionImg2, {
  //   scrollTrigger: {
  //     trigger: serviceSection2,
  //     start: 'top bottom',
  //     toggleActions: "play none none reverse",
  //     markers: true,
  //   },
  //   opacity: 1,
  //   scale: 1

  // })

  // serviceSections.forEach((element, index) => {
  //     gsap.to(element, {
  //         scrollTrigger: {
  //             trigger: element,
  //             start: 'top bottom',
  //             markers: true,
  //             toggleActions: 'play pause play pause',
  //             onEnter: () => element.classList.add('is-active'),
  //             onEnterBack: ()=> element.classList.add('is-active'),
  //             onLeaveBack: () =>element.classList.remove('is-active'),
  //             onLeave: () => element.classList.remove('is-active'),
  //         }
  //     });
  // });
};

const initXscroll = function () {
  const area = document.querySelector(".js-x-scroll");
  const wrap = document.querySelector(".js-items");
  const items = document.querySelectorAll(".js-item");
  const num = items.length;
  if (!area) {
    return;
  }

  //横幅を指定
  gsap.set(wrap, { width: num * 100 + "%" });
  gsap.set(items, { width: 100 / num + "%" });
  gsap.to(items, {
    xPercent: -100 * (num - 1),
    ease: "none",
    scrollTrigger: {
      trigger: area,
      start: "50% 50%", //開始位置
      end: () => "+=" + (area.offsetWidth + 500),
      pin: true,
      ease: "power1.in",
      scrub: 2,
    }
  });
}

const initCultureCatchScrollAnimtion = function () {
  const logo1 = document.querySelector(".js-culuture-catch1");
  const logo2 = document.querySelector(".js-culuture-catch2");
  const area = document.querySelector(".js-culuture-catches");
  if (!area) {
    return;
  }

  gsap.set(logo1, {x: 300})
  gsap.set(logo2, {x: -300})
  gsap.to(logo1, {
    x: 0,
    scrollTrigger: {
      trigger: area,
      start: "center bottom",
      end: "center center",
      ease: "power1.in",
      scrub: 2,
    }
  });
  gsap.to(logo2, {
    x: 0,
    scrollTrigger: {
      trigger: area,
      start: "center bottom", 
      end: "center center",
      ease: "power1.in",
      scrub: 2,
    }
  });
}

let mm = gsap.matchMedia();
initCultureCatchScrollAnimtion()
mm.add('(min-width: '+(breakPoint+1)+'px)', () => {
  initServiceParallax()
  initXscroll()
});